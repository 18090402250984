import { createSlice } from '@reduxjs/toolkit';

export const hotelAutocompleteCacheSlice = createSlice({
  name: 'hotelAutocompleteCache',
  initialState: {
    value: {},
  },
  reducers: {
    setAutocompleteCache: (state, autocomplete) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (!state.value[autocomplete.payload.key]) {
        state.value[autocomplete.payload.key] = autocomplete.payload.value;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAutocompleteCache, requestOngoing } =
  hotelAutocompleteCacheSlice.actions;

export default hotelAutocompleteCacheSlice.reducer;
