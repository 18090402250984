import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/userSlice';
import flightSegmentsReducer from './features/flightSegmentsSlice';
import hotelDestinationReducer from './features/hotelDestinationSlice';
import hotelAutocompleteCacheReducer from './features/hotelAutocompleteCacheSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    segments: flightSegmentsReducer,
    hotelDestination: hotelDestinationReducer,
    hotelAutocompleteCache: hotelAutocompleteCacheReducer,
  },
});
